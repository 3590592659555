html{
  scroll-behavior: smooth
}

.App {
  text-align: center;
}

a{
  color: black;
  text-decoration: none;
}

h2 {
  font-size: 52px;
  font-weight: 800;
  margin-bottom: 12px;
  margin-top: 0px;
}

.game {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.game-header {
  min-height: 1px;
  flex: 1 1;
  padding: 24px 24px 4px 24px;
  display: flex;
  border-bottom: 1px solid #dedede;
}

.game-header-container{
  text-align: left;
}

@media(max-width: 700px){

  .game-header{
    padding-bottom: 10px;
  }
  .game-header-container div {
    flex-direction: column;
    align-items: self-start;
    margin-bottom: 4px;
  }
}

@media(max-width: 550px){
  .game-header-container-description {
    display: none;
  }
}

@media(max-width: 500px){
  .keyboard{
    width: 100%;
  }
}

@media(max-width: 420px){
  .header-info{
    display: none !important;
  }

  .game-header {
    justify-content: space-between !important;
  }

  .tab-items p{
    margin-top: 0px;
    margin-bottom: 0px;
  }
}


.game-header-container-description{
  margin-top: 4px;
  margin-bottom: 0px;
}

.game-header .date{
  font-size: 19px;
  font-weight: 300;
  margin: 0px;
  margin-top: 5px;
}

h1 {
  margin: 0px 16px 0px 0px;
  font-size: 31px;
  font-weight: 900;
}

.game-wrapper {
  padding: 10px;
}

.game-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
}

.game-status {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.submit-button{
  text-align: center;
  max-height: 4.5em;
  padding: 0px 20px;
  background-color: #dedede;
  color: #7f7d7d;
  height: 46px;
  display: flex;
  border-radius: 37px;
  font-size: 18px;
  font-weight: bold;
  border: none;
  margin-top: 15px;
  align-items: center;
}

.submit-button-active{
  background-color: #fde456;
  color: #000;
  cursor: pointer;
}

.submit-button-active:hover{
  background-color: #f4d734;
}

.error{
  font-weight: 800;
  color: #1a88d2;
  animation: shaking 0.4s 1 ease-in;
}

.close-button{
  position: absolute;
  right: 19px;
  cursor: pointer;
}

@keyframes shaking {
  0% { transform: translateX(0) }
  25% { transform: translateX(5px) }
  50% { transform: translateX(-5px) }
  75% { transform: translateX(5px) }
  100% { transform: translateX(0) }
 }


/* Utility */

.w-50{
  width: 50%;
}

.f18 {
  font-size: 18px;
}

.f15 {
  font-size: 15px;
}

.f14 {
  font-size: 14px;
}

.w-100{
  width: 100%;
}

.flex-column{
  display: flex;
  align-items: center;
  flex-direction: column;
}

.flex-row{
  display: flex;
  align-items: center;
}

.align-start{
  align-items: flex-start;
}

.justify-between{
  justify-content: space-between;
}

.justify-end{
  justify-content: flex-end;
}

.pointer{
  cursor: pointer;
}

.bold{
  font-weight: bold !important;
}

.super-bold{
  font-weight: 900 !important;
}

.mb-15{
  margin-bottom: 15px !important;
}

.mt-0{
  margin-top: 0px !important;
}

.mt-20{
  margin-top: 20px;
}

.mb-0{
  margin-bottom: 0px !important;
}

.mr-10{
  margin-right: 10px;
}

.mb-10{
  margin-bottom: 10px;
}


.bg-green{
  background: #43a047;
}
.bg-yellow{
  background: #e4a81d;
}
.bg-grey{
  background: #757575;
}
.c-green{
  color: #43a047;
}
.c-yellow{
  color: #e4a81d;
}
.c-grey{
  color: #757575;
}

@media(max-width: 980px){

  .game-header{
    width: auto;
  }
  .game-container{
    width: 100%;
    padding: 0px;
  }
}
@media(max-width: 520px){
  h1{
    font-size: 26px;
  }

  .game-header{
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
  }

  .game-header-container{
    flex-direction: column;
    align-items: flex-end;
  }
}
