.word {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 5px;
  margin-bottom: 5px;

  .letter {
    width: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    line-height: 1;
    font-weight: bold;
    vertical-align: middle;
    box-sizing: border-box;
    text-transform: uppercase;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: 2px solid #d3d6da;
    width: 52px;
    height: 52px;
  }
}

.green-state-1 {
  animation: flip-green 200ms ease forwards;
}

.yellow-state-1 {
  animation: flip-yellow 200ms ease forwards;
}

.grey-state-1 {
  animation: flip-grey 200ms ease forwards;
}

.green-state-2 {
  animation: flip-green 200ms ease 300ms forwards;
  animation-delay: 200;
}

.yellow-state-2 {
  animation: flip-yellow 200ms ease 300ms forwards;
  animation-delay: 200;
}

.grey-state-2 {
  animation: flip-grey 200ms ease 300ms forwards;
  animation-delay: 200;
}

.green-state-3 {
  animation: flip-green 200ms ease 600ms forwards;
  animation-delay: 400;
}

.yellow-state-3 {
  animation: flip-yellow 200ms ease 600ms forwards;
  animation-delay: 400;
}

.grey-state-3 {
  animation: flip-grey 200ms ease 600ms forwards;
  animation-delay: 400;
}

.green-state-4 {
  animation: flip-green 200ms ease 900ms forwards;
}

.yellow-state-4 {
  animation: flip-yellow 200ms ease 900ms forwards;
}

.grey-state-4 {
  animation: flip-grey 200ms ease 900ms forwards;
}

.green-state-5 {
  animation: flip-green 200ms ease 1200ms forwards;
}

.yellow-state-5 {
  animation: flip-yellow 200ms ease 1200ms forwards;
}

.grey-state-5 {
  animation: flip-grey 200ms ease 1200ms forwards;
}

.click-state{
  animation: click-animation 100ms;
}

@keyframes flip-green {
  0% {
    color: black;
    transform: scaleY(1);
    border: 2px solid #d3d6da;
  }

  50% {
    background: white;
    transform: scaleY(0);
  }

  53% {
    color: white;
  }

  100% {
    transform: scaleY(1);
    background: #538d4e;
    border: 2px solid #538d4e;
    color: white;
  }
}

@keyframes flip-yellow {
  0% {
    color: black;
    transform: scaleY(1);
    border: 2px solid #d3d6da;
  }

  50% {
    background: white;
    transform: scaleY(0);
  }

  53% {
    color: white;
  }

  100% {
    transform: scaleY(1);
    background: #c9b458;
    border: 2px solid #c9b458;
    color: white;
  }
}

@keyframes flip-grey {
  0% {
    color: black;
    transform: scaleY(1);
    border: 2px solid #d3d6da;
  }

  50% {
    background: white;
    transform: scaleY(0);
  }

  53% {
    color: white;
  }

  100% {
    transform: scaleY(1);
    background: #787c7e;
    color: white;
    border: 2px solid #787c7e;
  }
}

@keyframes click-animation {
  0% {
    transform: scale(1)
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1)
  }
}
