.toast{
  background: black;
  border-radius: 5px;
  color: white;
  height: 21px;

  > div {
    height: 0px;
    font-weight: bold;

    .toast-component{
      z-index: 9999999;
      position: relative;
      p{
        font-size: 15px;
        line-height: 22px;
      }
      .icon-close{
        margin-left: 25px;
      }
    }
  }
}