.about-container {
  max-width: 493px;
  margin: 0 auto;
  text-align: left;
  font-size: 1rem;
  line-height: 1.5;
  margin-top: 30px;

  .button {
    background: #18a861;
    padding: 7px 23px;
    border-radius: 9px;
    color: white;
    font-weight: bold;
    font-size: 19px;
  }
}
