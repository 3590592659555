#nav-menu-content {
  color: #000;
  box-sizing: border-box;
  width: 100%;
  background-color: #fff;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  animation: slideOpen 0.2s ease-in;
  display: flex;
  position: absolute;
  top: 100px;
  left: 5px;
  height: calc(100vh - 110px);
  z-index: 999999;
  overflow-y: auto;
  max-width: 375px;
  box-shadow: 3px 5px 5px #00000026;
}

@media(max-width: 520px){
  #nav-menu-content{
    max-width: none;
    top: 70px;
  }
}

.close-x {
  color: #000;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 12px;
  right: 12px;
}

#nav-menu-container{
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 20px;
  margin-top: 40px;
}

#nav-menu-container a{
  color: black;
  text-decoration: none;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 16px;
}

#nav-menu-container a:hover{
  color: #43a047;
}

.nav-content-header-info{
  text-align: left;
  display: none;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid rgb(215, 215, 215);
}

.underline{
  text-decoration: underline;
}

h4{
  margin-bottom: 8px;
}

.menu-header{
  border-bottom: 1px solid rgb(212, 212, 212);
  width: 100%;
  padding-bottom: 15px;
  margin-bottom: 30px;
  * {
    text-align: left;
  }

  h2{
    font-size: 27px;
    margin-bottom: 5px;
  }

  p{
    font-size: 15px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

.games-container{
  background: #fff5cd;
  text-align: left;
  border-radius: 11px;
  margin-top: 20px;
  padding: 16px;
  max-width: 299px;

    h4{
      margin-top: 0px;
    }
}

@media(max-width: 420px){
  .nav-content-header-info{
    display: flex;
    flex-direction: column;
  }
}

@keyframes slideOpen {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}