.modal {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.modal-content {
  background: white;
  max-width: 700px;
  margin: 0 auto;
  border-radius: 20px;
  padding: 20px;
  height: 400px;
  width: 80%;
  position: relative;
  text-align: center;
}

.modal .modal-content h3 {
  font-size: 35px;
  font-weight: 900;
  margin-bottom: 0px;
  text-align: center;
}

.modal .modal-content p {
  margin-top: 5px;
  margin-bottom: 0px;
  text-align: center;
  font-size: 18px;
}

.modal-content button{
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  display: block;
  background: #44a047;
  border: none;
  padding: 15px 28px;
  border-radius: 39px;
  color: white;
  font-size: 18px;
  font-weight: bold;
  margin-top: 15px;
  cursor: pointer;
  margin-bottom: 33px;
}

.modal-content button:hover{
  background: #368e39;
}

.modal-content h4{
  text-align: center;
  font-size: 23px;
  font-weight: 900;
}