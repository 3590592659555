.rules-container{
  max-width: 483px;
  margin: 0 auto;
  text-align: left;
  font-size: 1rem;
  line-height: 1.5;

  .rules-letter{
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    max-width: 62px;
    height: 100%;
    min-height: 62px;
    color: white;
    font-size: 1.875rem;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 5px;
    user-select: none;
    cursor: default;
    margin-right: 10px;
  }

  .rules-example{
    max-width: 320px;
  }
}