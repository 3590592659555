.keyboard{
  height: 200px;
  margin: 0 8px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  &-row{
    display: flex;
    margin: 0 auto 8px;
    touch-action: manipulation;

    @media(max-width: 500px){
      width: 100%;
    }

    &-key{
      max-width: 43px;
      width: 43px;
      height: 58px;
      display: flex;
      margin: 0 auto 8px;
      touch-action: manipulation;
      font-size: 1.25em;
      font-weight: bold;
      border: 0;
      padding: 0;
      margin: 0 6px 0 0;
      border-radius: 4px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      user-select: none;
      background-color: #d3d6da;
      color: black;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      -webkit-tap-highlight-color: rgba(0,0,0,.3);

      @media(max-width: 500px){
        max-width: 53px;
        width: 100%;
      }
    }

    .backspace, .enter{
      width: 65px;
      max-width: 65px;
      font-size: 12px;
    }
  }
}